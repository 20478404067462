<template>
  <VHover #default="{hover}">
    <VCard
      v-bind="[$attrs,$props]"
      :flat="shadowIsNever"
      :min-height="$attrs['min-height'] || 124"
      :class="{ 'card-shadow' : !shadowIsNever,
                'card-shadow--hovered' : hover && shadowIsHover,
                'rounded-lg' : true,
                'card' : true}"
      v-on="$listeners"
    >
      <slot />
    </VCard>
  </VHover>
</template>

<script>
export default {
  name: 'CardWrapper',
  props: {
    shadow: {
      type: String,
      validator: (v) => ['always', 'hover', 'never'].includes(v),
      default: 'always',
    },
  },
  computed: {
    shadowIsHover() {
      return this.shadow === 'hover';
    },
    shadowIsAlways() {
      return this.shadow === 'always';
    },
    shadowIsNever() {
      return this.shadow === 'never';
    },
  },
};
</script>

<style lang="scss">

</style>
