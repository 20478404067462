<template>
  <VMenu
    offset-y
    :nudge-bottom="8"
    bottom
    max-width="466"
    min-width="250"
    rounded="lg"
    content-class="shadow"
    left
  >
    <template
      v-for="(_, scopedSlotName) in $scopedSlots"
      #[scopedSlotName]="slotData"
    >
      <slot
        :name="scopedSlotName"
        v-bind="slotData"
      />
    </template>
    <slot>
      <VList
        class="list"
        nav
      >
        <template v-for="(item,index) in menuList">
          <VListItem
            :key="index"
            link
            :data-test="item.dataTest || 'menu-action-button'"
            @click="item.action"
          >
            <VListItemIcon>
              <VIcon :color="generateIconColor(item.color)">
                {{ item.icon }}
              </VIcon>
            </VListItemIcon>
            <VListItemTitle :class="['tt-text-body-2',generateTextColor(item.color)]">
              {{ item.text }}
            </VListItemTitle>
          </VListItem>
          <VDivider
            v-if="showDivider(index,menuList.length)"
            :key="`divider-${index}`"
            inset
            class="my-1"
          />
        </template>
      </VList>
    </slot>
  </VMenu>
</template>

<script>
export default {
  name: 'MenuWrapper',
  props: {
    menuList: {
      type: [Array],
      default() {
        return [
          {
            icon: 'fal fa-pen',
            color: '',
            text: 'Редактировать',
            dataTest: '',
            action: () => this.$emit('click:menu-edit'),
          },
          {
            icon: 'fal fa-trash-alt',
            color: 'tt-light-red',
            text: 'Удалить',
            dataTest: '',
            action: () => this.$emit('click:menu-delete'),
          },
        ];
      },
    },
  },
  methods: {
    generateTextColor(color) {
      if (color) return `${color}--text`;
      return undefined;
    },
    generateIconColor(color) {
      if (color) return color;
      return undefined;
    },
    showDivider(index, arrayLength) {
      return index + 1 !== arrayLength;
    },
  },
};
</script>

<style scoped>

</style>
