// eslint-disable-next-line import/prefer-default-export
export const generateAvatarText = (string) => {
  // Если нет имени, то возвращается "AH" - Anonymous Human
  if (!string) return 'AH';
  const avatarText = string.split(' ');
  if (avatarText.length >= 2) {
    return avatarText[0].charAt().toUpperCase() + avatarText[1].charAt(0).toUpperCase();
  }

  return avatarText[0].charAt(0).toUpperCase() + avatarText[0].charAt(1).toUpperCase();
};
export const stringTruncate = (string, length) => {
  if (!string) {
    return null;
  }
  if (string.length > length) {
    return `${string.slice(0, length)}...`;
  }
  return string;
};
