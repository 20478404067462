<template>
  <VContainer>
    <VRow justify="center">
      <VCol
        cols="12"
        data-test="empty-section-image"
      >
        <slot name="image">
          <VRow justify="center">
            <VCol cols="auto">
              <div class="empty-section__image">
                <VImg
                  :src="require('@/assets/images/empty_folder.png')"
                  max-width="253"
                />
                <div
                  v-if="showArrow"
                  class="empty-section__image--arrow"
                >
                  <VImg :src="require('@/assets/images/arrow.svg')" />
                </div>
              </div>
            </VCol>
          </VRow>
        </slot>
      </VCol>
    </VRow>
    <VRow
      justify="center"
    >
      <VCol
        class="tt-text-title-2"
        cols="auto"
        data-test="empty-section-title"
      >
        <slot name="title">
          {{ title }}
        </slot>
      </VCol>
    </VRow>
    <VRow
      justify="center"
      dense
    >
      <VCol
        class="tt-text-body-1 tt-light-mono-64--text"
        cols="auto"
        data-test="empty-section-test"
      >
        <slot name="text">
          {{ text }}
        </slot>
      </VCol>
    </VRow>
    <template v-if="$slots.optional">
      <VRow
        justify="center"
        dense
      >
        <VCol
          cols="auto"
          data-test="empty-section-optional"
        >
          <slot name="optional" />
        </VCol>
      </VRow>
    </template>
  </VContainer>
</template>

<script>
export default {
  name: 'EmptySection',
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.empty-section{
  &__image{
    position: relative;
    &--arrow{
      top: 40px; right: -260px;
      position: absolute;
    }
  }

}
</style>
