<template>
  <VContainer class="pa-0">
    <VRow dense>
      <VCol class="tt-text-headline-2">
        {{ title }}
      </VCol>
    </VRow>
    <VRow dense>
      <VCol
        class="tt-text-body-1 tt-light-mono-64--text"
        cols="6"
      >
        {{ description }}
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'NoResult',
  props: {
    title: {
      type: String,
      default: ' По вашему запросу ничего не найдено',
    },
    description: {
      type: String,
      default: 'Убедитесь, что все слова поискового запроса написаны без ошибок или '
        + 'попробуйте использовать другие ключевые слова.',
    },
  },
};
</script>

<style scoped>

</style>
